@charset "utf-8";
/* CSS Document */

@import "import.scss";

@mixin sliderDotsBaseSP {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    width: 8px;
    height: 8px;
    margin: 0 8px;
    button {
      font-size: 0;
      line-height: 0;
      display: block;
      background: #cccccc;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      padding: 0;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      &:before {
        content: none;
      }
    }
    &.slick-active {
      button {
        background: $mainBlkColor;
      }
    }
  }
}
@mixin sliderDotsBasePC {
  bottom: -34px;
  li {
    position: relative;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin: 0 10px;
    button {
      width: 10px;
      height: 10px;
    }
  }
}

/* COMMON PARTS-------------------------------------------*/
.pagetype-home {
  .home-ttl {
    color: $mainBlkColor;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 769px) {
  .pagetype-home {
    .home-ttl {
      margin-bottom: 35px;
    }
  }
  .slick-arrow {
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.6;
    }
  }
}
.page-bd {
  padding-bottom: 0;
}

/* メインビジュアル-------------------------------------------*/
.mv-area {
  margin-bottom: 12px;
  .slider-box {
    opacity: 0;
    transition: all 0.3s ease;
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 40px;
    .slide {
      margin: 0;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .slick-arrow {
      width: 41px;
      height: 41px;
      background: rgba(255,255,255,0.9);
      position: absolute;
      bottom: 3%;
      top: 0;
      margin: auto;
      z-index: 10;
      cursor: pointer;
      .icon-arrow01 {
        position: absolute;
        &::before {
          width: 11px;
          height: 11px;
          border-width: 3px;
        }
      }
    }
    .next {
      right: 0;
      .icon-arrow01 {
        left: auto;
        right: 16px;
      }
    }
    .prev {
      left: 0;
      .icon-arrow01 {
        left: 14px;
        &:before {
          transform: rotate(225deg);
        }
      }
    }
    .slick-dots {
      @include sliderDotsBaseSP;
    }
  }
}
@media screen and (min-width: 769px) {
  .mv-area {
    margin-bottom: 45px;
    .slider-box {
      .slide {
        a {
          &:hover {
            img {
              opacity: 0.6;
            }
          }
        }
      }
      .slick-slide {
        opacity: 0.5;
      }
      .slick-current {
        opacity: 1;
      }
      .slick-dots {
        @include sliderDotsBasePC;
      }
      .slick-arrow {
        width: 52px;
        height: 52px;
        top: 0;
        bottom: 0;
        .icon-arrow01 {
          &::before {
            width: 14px;
            height: 14px;
            border-width: 2px;
          }
        }
      }
      .next {
        right: 23%;
        transform: translate(53px, 0);
        .icon-arrow01 {
          right: 25px;
          &:before {
          }
        }
      }
      .prev {
        left: 23%;
        transform: translate(-52px, 0);
        .icon-arrow01 {
          left: 21px;
          &:before {
          }
        }
      }
    }
  }
}

/* アイコンが並ぶメニュー-------------------------------------------*/
@media screen and (min-width: 769px) {
  .topnav-area {
    margin-bottom: 50px;
  }
}

/* お知らせ-------------------------------------------*/
.info-area {
  background: $bg2Color;
  padding: 20px 0 25px;
}
@media screen and (min-width: 769px) {
  .info-area {
    padding: 40px 0 50px;

  }
}

/* NEWS&EVENT-------------------------------------------*/
.news-area {
  padding-top: 35px;
  margin-bottom: 60px;
  .article-list {
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
    gap: 30px 20px;
    grid-template-areas:
      "article01 article01"
      "article02 article03"
      "article04 article05"
      "article06 article06"
      "article07 article08"
      "article09 article10";
    li {
      width: auto;
      margin-right: 0;
      margin-bottom: 0;
      //img {
        //width: 100%;
      //}
    }
    li:nth-child(1) { grid-area: article01; }
    li:nth-child(2) { grid-area: article02; }
    li:nth-child(3) { grid-area: article03; }
    li:nth-child(4) { grid-area: article04; }
    li:nth-child(5) { grid-area: article05; }
    li:nth-child(6) { grid-area: article06; }
    li:nth-child(7) { grid-area: article07; }
    li:nth-child(8) { grid-area: article08; }
    li:nth-child(9) { grid-area: article09; }
    li:nth-child(10) { grid-area: article10; }
    li:nth-child(1),
    li:nth-child(6) {
      .img-area {
        .icon-new {
          span {
            font-size: 18px;
            @include fontMedium;
            right: -5px;
            top: 15px;
          }
          &::after {
            border-width: 95px 95px 0 0;
          }
        }
      }
      .icon-category {
        font-size: 14px;
      }
      .date {
        font-size: 16px;
      }
      .title {
        font-size: 18px;
        -webkit-line-clamp: 2;
      }
    }
    &.d-flex {
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc(100% / 2 - 20px);
        margin-right: 20px;
        margin-bottom: 25px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:nth-child(1),
        &:nth-child(6) {
          .img-area {
            .icon-new {
              span {
                font-size: 10px;
                right: -2px;
                top: 7px;
              }
              &::after {
                border-width: 45px 45px 0 0;
              }
            }
          }
          .icon-category {
            font-size: 10px;
          }
          .date {
            font-size: 10px;
          }
          .title {
            font-size: 12px;
            -webkit-line-clamp: 3;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .news-area {
    padding-top: 65px;
    margin-bottom: 100px;
    .article-list {
      margin-bottom: 40px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
      gap: 40px 24px;
      grid-template-areas:
        "article01 article01 article02 article03"
        "article01 article01 article04 article05"
        "article08 article07 article06 article06"
        "article10 article09 article06 article06";
      li {
        width: auto;
        .date {
          font-size: 14px;
        }
      }
      li:nth-child(10) {
        .img-area {
          .icon-new {
            &::after {
              border-width: 70px 70px 0 0;
            }
            span {
              font-size: 14px;
              right: -6px;
              top: 10px;
            }
          }
        }
        .date {
          font-size: 14px;
        }
        .title {
          font-size: 16px;
        }
      }
      li:nth-child(1),li:nth-child(6) {
        .img-area {
          .icon-new {
            &::after {
              border-width: 140px 140px 0 0;
            }
            span {
              font-size: 29px;
              right: -11px;
              top: 19px;
            }
          }
        }
        .icon-category {
          font-size: 20px;
        }
        .date {
          font-size: 18px;
        }
        .title {
          font-size: 20px;
        }
      }
      &.d-flex {
        li {
          width: calc(100% / 4 - 36px);
          margin-right: 24px;
          margin-bottom: 25px;
          &:nth-child(2n) {
            margin-right: 24px;
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:nth-child(1),
          &:nth-child(6) {
            .img-area {
              .icon-new {
                span {
                  font-size: 14px;
                  right: -6px;
                  top: 10px;
                }
                &::after {
                  border-width: 70px 70px 0 0;
                }
              }
            }
            .icon-category {
              font-size: 14px;
            }
            .date {
              font-size: 14px;
            }
            .title {
              font-size: 16px;
              -webkit-line-clamp: 2;
            }
          }
        }
      }
    }
  }
}

/* BLOG-------------------------------------------*/
.blog-area {
  margin-bottom: 53px;
}
@media screen and (min-width: 769px) {
  .blog-area {
    margin-bottom: 118px;
  }
}

/* 私達の取り組み-------------------------------------------*/
.works-area {
  background: $bg2Color;
  padding: 25px 0;
  .home-ttl {
    font-size: 18px;
  }
  .bnr-area {
    margin-bottom: 25px;
  }
  .works-list {
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(100% / 2 - 10px);
      margin-right: 20px;
      margin-bottom: 25px;
      font-size: 10px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      .ttl {
        margin-top: 5px;
        font-weight: bold;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .works-area {
    padding: 46px 0 70px;
    .home-ttl {
      font-size: 32px;
      margin-bottom: 40px;
    }
    .bnr-area {
      margin-bottom: 48px;
      a {
        display: block;
      }
    }
    .works-list {
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc(100% / 4 - 18px);
        margin-right: 24px;
        margin-bottom: 25px;
        font-size: 16px;
        &:nth-child(2n) {
          margin-right: 24px;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
        .ttl {
          margin-top: 10px;
        }
      }
    }
  }
}

/* サービスのご案内-------------------------------------------*/
.service-area {
  padding: 50px 0 57px;
  .service-list {
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(100% / 2 - 10px);
      margin-right: 20px;
      margin-bottom: 20px;
      border: 1px solid $lineColor;
      display: flex;
      &:nth-child(2n) {
        margin-right: 0;
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 22px 0;
        width: 100%;
      }
      .ttl {
        margin-top: 10px;
        font-weight: bold;
        text-align: center;
      }
    }
    .list01 {
      img {
        width: 50px;
      }
    }
    .list02 {
      img {
        width: 43px;
      }
    }
    .list03 {
      img {
        width: 43px;
      }
    }
    .list04 {
      img {
        width: 46px;
      }
    }
    .list05 {
      img {
        width: 31px;
      }
    }
    .list06 {
      img {
        width: 100px;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .service-area {
    padding: 100px 0 105px;
    .service-list {
      margin-bottom: 20px;
      li {
        width: calc(100% / 3 - 22px);
        margin-right: 32px;
        margin-bottom: 32px;
        &:nth-child(2n) {
          margin-right: 32px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        a {
          padding: 48px 0 40px;
        }
        .ttl {
          margin-top: 20px;
        }
      }
      .list01 {
        img {
          width: 99px;
        }
      }
      .list02 {
        img {
          width: 86px;
        }
      }
      .list03 {
        img {
          width: 86px;
        }
      }
      .list04 {
        img {
          width: 92px;
        }
      }
      .list05 {
        img {
          width: 63px;
        }
      }
      .list06 {
        img {
          width: 170px;
        }
      }
    }
  }
}

/* オンラインショッピング-------------------------------------------*/
.online-area {
  background: $bg2Color;
  padding: 25px 0 30q;
  .online-slider-box {
    margin-bottom: 35px;
    .slider-inner {
      padding-bottom: 40px;
    }
    .slick-dots {
      @include sliderDotsBaseSP;
      bottom: 0;
    }
    .slide {
      padding-right: 25px;
      .caption {
        margin-top: 5px;
        font-weight: bold;
        font-size: 10px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .online-area {
    padding: 46px 0 70px;
    .online-slider-box {
      margin: 0px auto 80px;
      padding: 0 10px;
      max-width: 1020px;
      .slick-dots {
        @include sliderDotsBasePC;
        bottom: -29px;
      }
      .slide {
        padding-right: 32px;
        .caption {
          margin-top: 12px;
          font-size: 15px;
        }
      }
    }
  }
}

/* メンバーズガイド-------------------------------------------*/
.members-area {
  padding: 40px 0 55px;
  .members-list {
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(100% / 2 - 10px);
      margin-right: 20px;
      margin-bottom: 25px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      img {
        border: 1px solid $lineColor;
      }
      .ttl {
        margin-top: 5px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .members-area {
    padding: 96px 0 60px;
    .members-list {
      li {
        width: calc(100% / 3 - 22px);
        margin-right: 32px;
        margin-bottom: 50px;
        &:nth-child(2n) {
          margin-right: 32px;
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        .ttl {
          margin-top: 14px;
        }
      }
    }
  }
}

/* SNS-------------------------------------------*/
.sns-area {
  background: url(/assets/images/home/sns-bg.jpg) no-repeat;
  background-size: cover;
  padding: 45px 0;
  .box-inner {
    background: #ffffff;
    padding: 25px 10px 30px;
  }
  .sns-ttl {
    @include fontLatin;
    font-size: 15px;
    position: relative;
    width: 164px;
    margin: 0 auto 17px;
    &::before {
      content: "";
      width: 40%;
      height: 1px;
      background: $pointColor;
      position: absolute;
      bottom: 8px;
      right: 2%;
    }
  }
  .sns-list {
    display: flex;
    justify-content: space-evenly;
    li {
      width: 40px;
    }
  }
}
@media screen and (min-width: 769px) {
  .sns-area {
    padding: 135px 0;
    .box-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 78px 10px;
    }
    .sns-ttl {
      font-size: 30px;
      @include fontMedium;
      margin: 0 70px 0 0;
      line-height: 1.5;
      width: auto;
    }
    .sns-list {
      li {
        width: 50px;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.ft-nav {
  display: none;
}